import $ from "jquery";

export default function subNav() {
    let triggers = $(".js-subnavigation-trigger");
    const header = document.querySelector(".header");

    triggers.each(function () {
        if ($(`#${this.dataset.target}--mobile`).length) {
            this.addEventListener("click", (e) => {
                e.preventDefault();
                this.classList.toggle("active");

                let targetMobile = document.querySelector(`#${this.dataset.target}--mobile`);
                let targetDesktop = document.querySelector(`#${this.dataset.target}--desktop`);

                if (targetMobile.classList.contains("active")) {
                    targetMobile.classList.remove("active");
                    targetDesktop.classList.remove("active");
                    this.classList.remove("active");
                    header.classList.remove("active");
                    this.blur();
                    this.setAttribute("aria-expanded", "false");
                } else {
                    triggers.each(function() {this.classList.remove("active")});
                    this.classList.add("active");
                    document.querySelectorAll(".subnavigation").forEach(el=>{el.classList.remove("active")});
                    document.querySelectorAll(".subnavigation__nav").forEach(el=>{el.classList.remove("active")});
                    targetMobile.classList.add("active");
                    targetDesktop.classList.add("active");
                    header.classList.add("active");
                    this.setAttribute("aria-expanded", "true");
                }
            });
        }
    });
}

import $ from 'jquery';

export default function equalHeight (parent, child) {
    $(parent).each(function eachParent (parentIndex, parentElement) {
        const children  = $(parentElement).find(child);
        const rows = {};

        children.each(function eachChild (childIndex, childElement) {
            $(childElement).height('auto');
            $(childElement).css('min-height', 'auto');
            const top = 'top: ' + $(childElement).position().top;
            if (rows[top]) {
                rows[top].push(childElement);
            }
            else {
                rows[top] = [childElement];
             }
        });

        $.each(rows, function(name, row) {
            const heights = row.map(function (el) {
                return $(el).height();
            });
            const maxHeight = Math.max.apply(null, heights);
            $.each(row, function(i, el) {
                $(el).css('min-height', `${maxHeight}px`);
            });
        });
    });
}

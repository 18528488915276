import $ from 'jquery';

export default function domAppend (trigger, targetMobile, targetDesktop, maxWidth) {
    $(window).on('resize', function(e) {
        if ($(window).width() <= maxWidth) {  
            $(trigger).appendTo(targetMobile);
        } 
        else {
            $(trigger).appendTo(targetDesktop);
        }
    });
    if ($(window).width() <= maxWidth) {  
        $(trigger).appendTo(targetMobile);
    } 
    else {
        $(trigger).appendTo(targetDesktop);
    }
}

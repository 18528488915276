import $ from 'jquery';
import select2 from 'select2';

select2($);

export default function setupSelect2(
    selector = '[data-js-select-multiple]',
) {
    $(selector).each(function() {
        const select = $(this);
        const placeholder = select.data('placeholder');
        const tags = select.data('add-tags');
        const no_results = select.data('no-results') || 'No results found';
        select.select2({
            placeholder: placeholder,
            tags: tags,
            language: {
                noResults: function () {
                  return no_results;
                },
            },
        });
    });
};

import $ from 'jquery';

export default function setupGDPR(
    selector = '[data-gdpr-preference]',
    trigger = '[data-gdpr-value]',
) {

    $(trigger).on('click', e => {
        e.preventDefault();
        const notice = $(e.target).closest(selector).first();
        const name = notice.data('gdpr-preference')
        const value = $(e.target).data('gdpr-value');
        if (notice) {
            dismissNotice(notice, name, value);
        }
    });

    function dismissNotice(notice, name, value) {
        setCookie(name, value);
        notice.fadeOut(200, ()=> {
            notice.remove();
        });
    }

    function getCookie(key) {
        var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
        return keyValue ? keyValue[2] : null;
    }

    function setCookie(key, value) {
        var today = new Date();
        var expire = new Date();
        expire.setTime(today.getTime() + 3600000 * 24 * 365);
        document.cookie = key + "=" + encodeURI(value) + ";expires=" + expire.toGMTString() + ';path=/';
    }
};

import $ from 'jquery';

export default function setupListFilter(
    filter = '[data-js-list-filter]',
    list = '[data-js-filtered-list]',
    item = '[data-js-filtered-list-item]',
    lang_selector = '.navigation-languages__item'
) {
    const original_list = $(list);
    const cloned_list = original_list.clone();
    const form = original_list.closest('form');

    if (!original_list.length) {
        return;
    }

    form.on('submit', function (e) {
        e.preventDefault();
    });

    filterList();
    $(filter).on('change.select2', filterList);

    function filterList() {
        var list_values = $(filter).first().val();
        original_list.empty();

        if (list_values.length) {
            list_values.forEach(element => {
                original_list.append(cloned_list.find(`[id=${element}`).first().clone());
            });
        }
        else {
            cloned_list.find(item).each((index, element)  => {
                original_list.append($(element).clone());
            });
        }
        const param_string = updateHistory(list_values);
        updateLanguageSelector(param_string, list_values);
    }

    function updateHistory(list_values) {
        const field_name = $(filter).attr('name');
        const params = {};
        params[field_name] = list_values;

        const existing_params = new URLSearchParams(window.location.search.replace('?', ''));
        for (let key of existing_params.keys()) {
            if (key !== field_name) {
                params[key] = existing_params.get(key);
            }
        }

        const param_query = $.param(params, true);
        const param_string = param_query ? `?${param_query}` : location.pathname;
        history.replaceState('', '', param_string);
        return param_string;
    }

    function updateLanguageSelector(param_string, list_values) {
        const field_name = $(filter).attr('name');
        const lang_selectors = $(lang_selector);
        lang_selectors.each((index, element) => {
            try {
                const lang = $(element).data('lang-code');
                const link = $($(element).find('a'));
                let url = new URL(link.attr('href'));
                let params = new URLSearchParams(url.search);

                params.delete(field_name);
                list_values.forEach(element => {
                    const item = original_list.find(`[id=${element}`);
                    const translated_value = item.data(`slug-${lang}`);
                    params.append(field_name, translated_value);
                });
                url.search = params.toString();
                link.attr('href', url);
            } catch (e) {}
        });
    }
};

import $ from 'jquery';

export default function gaExtras (selector) {

    if (selector) {

        $(document).on('click keypress', selector, function (e) {

            const ga = window.__gaTracker;
            const DOWNLOAD = 'Download';
            const SHARE = 'Share';

            if (ga && ga.create && isValidAction(e)) {

                const link = $(this).attr('href');
                const label = $(this).data('event-label');
                const category = $(this).data('event-category') || DOWNLOAD;
                const doLink = () => {
                    if (category != SHARE) {
                        location.href = link;
                    }
                    clearTimeout(linkTimeout);
                }
                const linkTimeout = setTimeout(doLink, 1500);

                ga('send', {
                    hitType: 'event',
                    eventCategory: category,
                    eventAction: link,
                    eventLabel: label,
                    hitCallback: doLink,
                });

                e.preventDefault();
            }
        });
    }

    // test for valid click or enter key press
    function isValidAction(e) {
        return e.key === 'Enter' || e.type === 'click';
    }

}

import $ from 'jquery';

export default function setupIntegerCountup(
    elements = 'h1, h2, h3',
    ancestors = '.js-animate-integers',
    class_name = 'integer-text',
    duration = 2000,
) {
    const animationDuration = duration;
    const frameDuration = 1000 / 60;
    const totalFrames = Math.round( animationDuration / frameDuration );
    const easeOutQuad = t => t * ( 2 - t );
    const text_elements = $(elements);

    text_elements.each((index, el) => {
        const element = $(el);
        if (!element.closest(ancestors).length) {
            return;
        }
        element.html(element.html().replace(/\d+/g, `<span class="${class_name}">$&</span>`));
    });

    // The animation function, which takes an Element
    const animateCountUp = el => {

        if (el.inited) {
            return;
        }
        el.inited = true;

        let frame = 0;
        const countTo = parseInt( el.innerHTML, 10 );

        // Start the animation running 60 times per second
        const counter = setInterval( () => {
            frame++;

            // Calculate our progress as a value between 0 and 1
            // Pass that value to our easing function to get our
            // progress on a curve
            const progress = easeOutQuad( frame / totalFrames );

            // Use the progress value to calculate the current count
            const currentCount = Math.round( countTo * progress );

            // If the current count has changed, update the element
            if ( parseInt( el.innerHTML, 10 ) !== currentCount ) {
                el.innerHTML = currentCount;
            }

            // If we’ve reached our last frame, stop the animation
            if ( frame === totalFrames ) {
                clearInterval( counter );
            }
        }, frameDuration );
    };

    let callback = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                animateCountUp(entry.target);
            };
        });
    };

    let observer = new IntersectionObserver(callback, {root: document, threshold: 0.1});
    $(`.${class_name}`).each((index, el) => {
        observer.observe(el);
    });
};
